import { Controller } from 'stimulus';
import { debounce } from 'lodash';
import Swiper, { A11y, Pagination } from 'swiper';

Swiper.use([A11y, Pagination]);

export default class extends Controller {
  static targets = ['slider', 'pagination']

  connect() {
    this.debouncedHandleResize = debounce(this.handleResize.bind(this), 150);
    window.addEventListener('resize', this.debouncedHandleResize);
    this.handleResize();
  }

  disconnect() {
    window.removeEventListener('resize', this.debouncedHandleResize);
    this.removeSwiper();
  }

  initSwiper() {
    // short circuit if swiper is already initialized
    if (this.swiper) return

    // initialize swiper
    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        a11y: {
          enabled: true,
        },
      },
    });
  }

  removeSwiper() {
    // short circuit if swiper is not initialized
    if (!this.swiper) return

    // destroy the swiper instance, using the built-in swiper method
    this.swiper.destroy();

    // set this.swiper to null to indicate that it has been destroyed, so that it can be re-initialized if necessary
    this.swiper = null;
  }

  handleResize() {
    const windowWidth = window.innerWidth;

    // we only want to initialize swiper if the window width is less than 768px
    if (windowWidth <= 768) {
      this.initSwiper();
    } else {
      // otherwise, remove swiper if it exists
      this.removeSwiper();
    }
  }
}
